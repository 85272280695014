import React from "react"
import { useTranslation } from "@3nvi/gatsby-theme-intl"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ForkArmsLayout from "../components/fork-arms-layout"

const Standard = ({data}) => {
  const { t } = useTranslation()
  return (
    <Layout>

        <ForkArmsLayout
            LHImage={data.lhimage.childImageSharp.gatsbyImageData}
            LHText={data.lhtext.childMarkdownRemark.html}
            RHImage={data.rhimage.childImageSharp.gatsbyImageData}
            RHText={data.rhtext.childMarkdownRemark.html}
            Stamps={data.stamps.nodes}
            PDFs={data.downloads.nodes}
            title={t("standard.image-title")}
            stampText={t("standard.stamp-text")}
        ></ForkArmsLayout>

    </Layout>
  )
}

export default Standard

export const pageQuery = graphql`

query($lang: String) {

  downloads: allFile(
    filter: { relativeDirectory: {eq: "fork-arms"}, sourceInstanceName: {eq: $lang}, extension: {eq: "pdf"} }
  ) {
    nodes {
      publicURL
      name
    }
  }

  stamps: allFile(
    filter: { relativeDirectory: {eq: "fork-arms/stamps"}, sourceInstanceName: {eq: $lang}, extension: {eq: "jpeg"} }
  ) {
    nodes {
        id
        childImageSharp {
          gatsbyImageData 
        }   
    }
  }

  lhimage: file(relativeDirectory: {eq: "fork-arms"}, sourceInstanceName: {eq: $lang}, name: {eq: "standard-lh"}) {
    id
      childImageSharp {
        gatsbyImageData (
            layout: FULL_WIDTH
        )
      }   
  }

  rhimage: file(relativeDirectory: {eq: "fork-arms"},sourceInstanceName: {eq: $lang}, name: {eq: "standard-rh"}) {
    id
      childImageSharp {
        gatsbyImageData (
            layout: CONSTRAINED
        )
      }   
  }

  lhtext: file(relativeDirectory: {eq: "fork-arms"}, sourceInstanceName: {eq: $lang}, name: {eq: "standard-lh-text"}) {
    id
      childMarkdownRemark {
        html
      }
  }

  rhtext: file(relativeDirectory: {eq: "fork-arms"}, sourceInstanceName: {eq: $lang}, name: {eq: "standard-rh-text"}) {
    id
      childMarkdownRemark {
        html
      }
  }

}

`;